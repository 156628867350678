
import RegisterNowHeaderLinkNotActive from "@/components/views/loggedOut/headerComponents/registerNow/RegisterNowHeaderLinkNotActive.vue";
import RegisterNowHeaderRegular from "@/components/views/loggedOut/headerComponents/registerNow/RegisterNowHeaderRegular.vue";
import { RegisterNowHeaderType } from "@/types/localtypes";
import { JFFormControl, JFTextField } from "jfrog-ui-vue-essentials";
import { isRestClientError } from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component({
  name: "RegisterNow",
  $_veeValidate: { validator: "new" },
  components: {
    JFTextField,
    JFFormControl,
  },
})
export default class RegisterNow extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Action("showAppLoadingMask", { namespace: "application" })
  showAppLoadingMask!: () => void;
  @Action("hideAppLoadingMask", { namespace: "application" })
  hideAppLoadingMask!: () => void;
  @Getter("registerNowHeaderType", { namespace: "dynamicHeader" })
  registerNowHeaderType!: RegisterNowHeaderType;
  email = "";

  get registerNowHeaderComponent() {
    switch (this.registerNowHeaderType) {
      case "linkNotActive":
        return RegisterNowHeaderLinkNotActive;
      case "regular":
      default:
        return RegisterNowHeaderRegular;
    }
  }

  toLogin() {
    this.$router.push({ path: "/login" });
  }

  get shouldDisableSubmitBtn() {
    return this.errors.any() || !this.email;
  }

  onEnterPress() {
    this.sendMail();
  }

  async sendMail() {
    if (!(await this.$validator.validateAll())) {
      return;
    }
    this.showAppLoadingMask();
    try {
      await this.$jfUsers.registerNow(this.email);
      this.hideAppLoadingMask();
      this.handleAfterSendingMail();
    } catch (e) {
      this.$log.error(e);
      this.hideAppLoadingMask();
      if (isRestClientError(e) && e.httpBody) {
        return this.$jfNotification.error({
          text: this.$jfMessages.generateMailLink(e.httpBody.message || (e.httpStatusText as string)),
        });
      }
      let error = e.httpStatusText || e;
      if (e.httpStatus === 429) {
        error = this.$jfMessages.too_many_requests;
      }
      if (e.httpStatus === 500) {
        error = this.$jfMessages.app_something_went_wrong;
      }
      this.$jfNotification.error({ text: error });
    }
  }

  handleAfterSendingMail() {
    this.$jfNotification.success({ text: this.$jfMessages.register_now_success, duration: -1 });
    this.toLogin();
  }

  mounted() {
    this.globalBus.$emit("shouldCheckLastLoginFlag");
    ((this.$refs["register-now-user-email"] as Vue).$el.querySelector("input") as HTMLInputElement).focus();
  }
}
